.confirm-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-family: "Bebas Neue";
    font-size: 2rem;
}

.confirm-subtitle {
    display: flex;
    justify-content: center;
    width: 100%;
    font-family: "Bebas Neue";
    font-size: 1.5rem;
    margin-top: 20px;
}