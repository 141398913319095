.item-header {
    font-family: 'Bebas Neue';
    font-size: 2rem;
}

.button-text {
    font-size: 1.4rem;
    color: white;
}

.drag-text {
    font-family: 'Bebas Neue';
    font-size: 1.4rem;
    color: black;
}