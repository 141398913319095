@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


.title-text {
    font-family: "Bebas Neue";
    font-size: 2rem;
}

.box-shadow {
    box-shadow: 1px 2px 9px red;
    margin: 1em;
    padding: 1em;
}

.add-item-button:hover {
    background: black !important;
    color: white;
}

.review-line {
    font-size: 2vh;
}

.review-item {

    margin-left: 2rem;
    font-size: 2vh;

}

.review-container {
    font-size: min(1.5rem, 5vw)
}

.button-container {
    color: white;
}

.highlight-text {
    color: #242424;
    font-size: larger;
    font-family: "Bebas Neue";
}



.item-button-text {
    font-family: "Bebas Neue";
    font-size: 1.2rem;
}

@media screen and (max-width: 900px) {
    .item-button-text {
        font-size: 1rem;
        /* Adjust the font size for smaller screens */
    }
}

.button-text {
    z-index: 5;
    font-family: "Bebas Neue";
    color: white;
    text-emphasis-color: white;
}

.review-button {
    color: white;
}

.review-button:hover {
    background: black !important;
    color: white;
}

.summary-header {
    font-family: "Bebas Neue";
    font-size: 1.2rem;
}

.summary-row {
    font-family: "Bebas Neue";
    font-size: 1.2rem;
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.summary-subrow {
    font-family: "Bebas Neue";
    font-size: 1rem;
    width: 90%;
    display: flex;
    direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
}


.warning-row {
    font-family: "Bebas Neue";
    font-size: 0.7rem;
    color: red;
}

.summary-subheader {
    font-family: "Bebas Neue";
    font-size: 1rem;
}