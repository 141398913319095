.box-title {
    font-family: "Bebas Neue";
    font-size: 1.2rem;
    color: white;
    padding: 0.6rem;
}

.box-header {
    font-family: "Bebas Neue";
    font-size: 1.2rem;
    margin-left: 0.6rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.box-head {
    font-family: "Bebas Neue";
    font-size: 1.2rem;
    margin-left: 0.6rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

.box-text {
    font-size: 1rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    margin-left: 0.6rem;
    font-weight: 400;
}

.table-header {
    font-family: "Bebas Neue";
    font-size: 1.5rem;
}

@media screen and (max-width: 900px) {

    /* Adjust the max-width as needed */
    .table-header {
        font-size: 1rem;
        /* Adjust the font size for mobile devices */
    }
}

.file-header {
    font-family: "Bebas Neue";
    font-size: 1.2rem;
}

.profile-header {
    font-size: min(5vw, 1.8rem)
}

.claim-button {
    width: 100%;
    height: 10vh;
}

.button-text {
    color: white;
}

.payment-details-text {
    font-family: "Bebas Neue";
    color: black;
    font-size: 2rem;
}

.payment-details-subtext {
    font-size: 1.3rem;
    margin: 1rem;
    text-align: center;
    vertical-align: middle;
}

.register-details-text {
    font-family: "Bebas Neue";
    color: black;
    font-size: 1.5rem;
}

.big-button-text {
    font-family: "Bebas Neue";
    color: white;
    font-size: 2rem;

}

@media screen and (max-width: 400px) {
    .big-button-text {
        font-size: 1.3rem;
    }
}

.info-box {
    font-family: "Bebas Neue";
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    font-size: 1rem;
}

.escrow-status-text {
    font-family: "Bebas Neue";
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
}

.green-text {
    color: green;
}

.red-text {
    color: red;
}

.grey-text {
    color: darkgray;
}

.dialog-text {
    margin: 3rem;
    font-family: "Bebas Neue";
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.confirmation-text {
    margin: 3rem;
    font-family: "Bebas Neue";
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.confirmation-button {
    margin: 3rem;
    font-family: "Bebas Neue";
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
}

.complaint-warning {
    text-align: center;
    font-family: "Bebas Neue";
    color: black;
    font-size: 1.3rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

}