@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.sidebar-header-text {
    font-family: "Bebas Neue";
    font-size: 2rem;
    margin: 6;
}

.sidebar-container {
    height: "calc(100vh - 1000px) !important"
}

.bottom-Container {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
}