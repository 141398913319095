.notification-header {
    font-family: "Bebas Neue";
    font-size: 1.5rem;
    margin-bottom: 10px;
    margin-top: 10px;
}

.notifications-header-text {
    font-family: "Bebas Neue";
    font-size: 2rem;
}