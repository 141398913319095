.dashboard-title {
    font-family: "Bebas Neue";
    font-size: 2rem;
}

.selector-text {
    font-family: "Bebas Neue";
    font-size: 1.2rem;
    color: black;
}

.datagrid-subheader {
    font-family: "Bebas Neue";
    font-size: 1.5rem;

}