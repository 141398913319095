.plaid-button {
    width: "100%";
    height: "10vh";
    background-color: "black";
}

.plaid-text {
    font-family: "Bebas Neue";
    color: white;
    font-size: 2rem;
}