@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.profile-item-title {
    font-family: 'Bebas Neue';
    font-size: 1.5rem;
}

.dialog-title {
    font-family: 'Bebas Neue';
    font-size: 2rem;
}

.header-text {
    font-family: "Bebas Neue";
    font-size: 2rem;
}