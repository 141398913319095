.dashboard-button {
    font-family: 'Bebas Neue';
    font-size: 1rem;
    color: black;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboard-button:hover {
    font-family: 'Bebas Neue';
    font-size: 1rem;
    color: black;
}