.trustap-login-text {
    font-family: "Bebas Neue";
    color: black;
    font-size: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}