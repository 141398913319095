@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.header-text {
    font-family: "Bebas Neue";
    font-size: 2rem;
    margin: 6;
}

.option-text {
    font-family: "Bebas Neue";
    font-size: 2rem;
    margin: 6;
}

.child-grid:hover {
    background-color: black;
    color: white
}

.centered-text {
    display: flex;
    align-items: center;
    /* Center vertically */
    justify-content: center;
    /* Center horizontally */
    text-align: center;
    font-family: "Bebas Neue";
    font-size: 2rem;
    margin: 6;
}