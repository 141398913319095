.splash-text {
    font-family: "Bebas Neue";
}

.login-text {
    font-family: "Bebas Neue";
    width: 80px;
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
    font-size: 2rem;
}

@media screen and (max-width: 900px) and (max-height: 400px) {
    .login-text {
        font-size: 1rem;
        /* Adjust the font size for smaller screens */
    }
}

.error-text {
    font-family: "Roboto Condensed";
    font-size: 1.2rem;
    color: red;
}

.submit-text {
    font-family: "Bebas Neue";
    color: white;
    font-size: 1.5rem;
}

.login-button:hover {
    background: black !important;
}


.samplebody {
    padding: 0;
    margin: 0 !important;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-family: "Bebas Neue";
    overflow: hidden;
    /* Prevent scrolling */
}

.containerbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* Prevent content from overflowing */
}


.kashd-headline {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    font-weight: 200;
    font-size: 15vw;
    text-transform: uppercase;
    mix-blend-mode: screen;
}

@media screen and (max-width: 500px) {
    .kashd-headline {
        font-weight: 200;
        font-size: 30vw;
        /* Adjust the font size for smaller screens */
    }
}

.kashd-splash-text {
    z-index: 2;
    color: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    font-weight: 200;
    font-size: 15vw;
    text-transform: uppercase;
    text-align: center;
    mix-blend-mode: normal;
}

.kashd-subheader {
    z-index: 2;
    color: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    font-weight: 200;
    font-size: 4vw;
}

.splash-header {
    font-family: "Bebas Neue";
    z-index: 2;
    color: black;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    font-weight: 200;
    font-size: 3vw;
}


.box-header {
    font-family: "Bebas Neue";
    z-index: 2;
    color: black;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0;
    font-weight: 200;
    font-size: 3vw;
}




@media screen and (max-width: 500px) {
    .kashd-splash-text {
        z-index: 2;
        color: black;
        font-weight: 200;
        font-size: 30vw;

        /* Adjust the font size for smaller screens */
    }
}


.login-register-text {
    font-size: 5vw;
}

@media screen and (max-width: 500px) {
    .login-register-text {
        font-size: 10vw;
        /* Adjust the font size for smaller screens */
    }
}


.options-stack {
    background-color: red;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.forgot-password {
    font-family: "Bebas Neue";
    font-size: 2vw;
}

@media screen and (max-width: 500px) {
    .forgot-password {
        font-size: 6vw;
        /* Adjust the font size for smaller screens */
    }
}